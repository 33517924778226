import React, { useState, useEffect } from 'react';
import './WhyChooseUs.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; 

const WhyChooseUs = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleAnswer = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index); // Toggle answer visibility
  };

  useEffect(() => {
    // Select all FAQ items
    const faqItems = document.querySelectorAll('.faq-item');

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger the callback when 10% of the element is in the viewport
    };

    // IntersectionObserver callback function
    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add 'revealed' class to trigger animation
          entry.target.classList.add('revealed');
        } else {
          // Remove 'revealed' class when the element is out of view
          entry.target.classList.remove('revealed');
        }
      });
    };

    // Initialize observer
    const observer = new IntersectionObserver(handleIntersection, options);

    // Observe all FAQ items
    faqItems.forEach(item => observer.observe(item));

    // Cleanup observer on unmount
    return () => {
      faqItems.forEach(item => observer.unobserve(item));
    };
  }, []);

  return (
    <div className="why-choose-us">
      <div className="content">
        <h2>Why Choose Us?</h2>
        <div className="faq">
          <div className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(1)}>
              <h4>What sets Dreamscape Studios apart from other production companies?</h4>
              {activeQuestion === 1 ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {activeQuestion === 1 && <p className="answer">
              At Dreamscape Studios, we combine years of expertise with a passion for storytelling. Our team is dedicated to crafting visually stunning and impactful productions, ensuring that your brand's vision is brought to life with precision and creativity. From pre-production to post-production, we offer end-to-end services that guarantee exceptional results.
            </p>}
          </div>
          <div className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(2)}>
              <h4>How do you handle tight deadlines and complex projects?</h4>
              {activeQuestion === 2 ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {activeQuestion === 2 && <p className="answer">
              We thrive under pressure. Our project management team works seamlessly to keep everything on track, ensuring deadlines are met without compromising on quality. Our experience in managing complex productions allows us to adapt quickly and efficiently, while maintaining a high level of professionalism throughout the entire process.
            </p>}
          </div>
          <div className="faq-item">
            <div className="faq-question" onClick={() => toggleAnswer(3)}>
              <h4>What kind of support can we expect throughout the production process?</h4>
              {activeQuestion === 3 ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {activeQuestion === 3 && <p className="answer">
              We provide constant support, from initial concept development to the final cut. Our team is available at every stage of the process to answer your questions and provide updates. With clear communication and a collaborative approach, we make sure you're involved in every aspect of the production, ensuring the final result aligns with your goals and vision.
            </p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
