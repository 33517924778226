import React, { useEffect } from 'react';
import './Hero.css';
import logo from '../assets/logo.png'; 

const Hero = () => {
  useEffect(() => {
    const canvas = document.getElementById('particles-js');
    const ctx = canvas.getContext('2d');

    // Set canvas size dynamically
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Particle class
    class Particle {
      constructor(x, y, size, color) {
        this.x = x;
        this.y = y;
        this.size = size;
        this.color = color;
        this.speedX = (Math.random() * 2 - 1) * 0.5;  
        this.speedY = (Math.random() * 2 - 1) * 0.5;  
        this.initialSize = size;
      }

      // Update particle position
      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        // Reset particle position if it goes out of bounds
        if (this.x > canvas.width) this.x = 0;
        if (this.x < 0) this.x = canvas.width;
        if (this.y > canvas.height) this.y = 0;
        if (this.y < 0) this.y = canvas.height;

        // Gradually shrink the particle size over time
        if (this.size > 0.5) this.size -= 0.05; 
      }

      // Draw particle
      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    // Particle generation
    const particlesArray = [];
    const numberOfParticles = 150; // Increase number of particles

    for (let i = 0; i < numberOfParticles; i++) {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = Math.random() * 5 + 2;  // Reduced size for smaller particles
      const color = '#ffffff'; // White color for particles
      particlesArray.push(new Particle(x, y, size, color));
    }

    // Particle animation loop
    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);  // Clear canvas

      particlesArray.forEach((particle, index) => {
        particle.update();
        particle.draw();

        // Connect particles with lines if they are close enough
        for (let j = index; j < particlesArray.length; j++) {
          const dx = particle.x - particlesArray[j].x;
          const dy = particle.y - particlesArray[j].y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 150) {  // Increased connection range to 150px
            const opacity = 1 - distance / 150;  // Fade the line with distance
            ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;  // White color with opacity
            ctx.lineWidth = 0.7;
            ctx.beginPath();
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(particlesArray[j].x, particlesArray[j].y);
            ctx.stroke();
          }
        }
      });

      requestAnimationFrame(animateParticles);  // Loop the animation
    }

    animateParticles();

    // Adjust canvas size on window resize
    window.addEventListener('resize', () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    });
  }, []);

  return (
    <div className="hero">
      <canvas id="particles-js"></canvas>
      <div className="hero-content">
        {/* Logo Import */}
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-img" />
        </div>
        <h1>Your Creative Journey Starts Here</h1>
        <p>Bringing Your Vision to Life with Expert Production</p>
        <a href="./about" className="cta-button">Learn More</a>
      </div>
    </div>
  );
};

export default Hero;
