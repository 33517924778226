import React from 'react';
import HeroSection from '../components/HeroSection';
import BriefIntroduction from '../components/BriefIntroduction'
import MissionVisionValues from '../components/MissionVisionValues';
import KeyAchievements from '../components/KeyAchievements';
import MeetTheTeam from '../components/MeetTheTeam';
import Testimonials from '../components/Testimonials';
import HireUsNow from '../components/HireUsNow'
import Footer from '../components/Footer'

const About = () => {
  return (
    <div>
      <HeroSection /> 
      <BriefIntroduction />
      <MissionVisionValues />
      <KeyAchievements />
      <MeetTheTeam />
      <Testimonials />
      <HireUsNow />



      <Footer />      
    </div>
  );
};

export default About;
