import React from 'react';
import { FaBullhorn, FaEye, FaUsers } from 'react-icons/fa';
import './MissionVisionValues.css';

const MissionVisionValues = () => {
  const sections = [
    {
      id: 1,
      icon: <FaBullhorn />,
      title: 'Our Mission',
      description:
        'To deliver impactful and creative video content that resonates with audiences while providing exceptional production services that support the entire creative process.',
    },
    {
      id: 2,
      icon: <FaEye />,
      title: 'Our Vision',
      description:
        'To be a global leader in video production by fostering innovation, creativity, and collaboration, creating memorable experiences for our clients and audiences worldwide.',
    },
    {
      id: 3,
      icon: <FaUsers />,
      title: 'Our Values',
      description:
        'We believe in integrity, collaboration, excellence, and passion. We value the relationships we build with clients, partners, and our team, aiming for success through teamwork and mutual respect.',
    },
  ];

  return (
    <section className="mission-vision-values-section">
      <div className="mission-vision-values-container">
        <h2 className="mission-vision-values-title">Our Mission, Vision & Values</h2>
        <div className="mission-vision-values-items">
          {sections.map((section) => (
            <div className="mission-vision-values-item" key={section.id}>
              <div className="icon-container">{section.icon}</div>
              <div className="text-content">
                <h3 className="section-title">{section.title}</h3>
                <p className="section-description">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default MissionVisionValues;
