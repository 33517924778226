import React from 'react';
import { FaTrophy, FaCalendar, FaUsers, FaFilm } from 'react-icons/fa';
import './KeyAchievements.css';

const KeyAchievements = () => {
  const milestones = [
    {
      id: 1,
      icon: <FaTrophy />,
      title: 'Award Winning Productions',
      description: 'Recognized with industry awards for our exceptional video production quality.',
      // stat: '5+ Awards',
    },
    {
      id: 2,
      icon: <FaCalendar />,
      title: 'Years in Business',
      description: 'Serving clients with passion and expertise for years.',
      // stat: '10+ Years',
    },
    {
      id: 3,
      icon: <FaUsers />,
      title: 'Happy Clients',
      description: 'Trusted by brands and agencies all around.',
      // stat: '10+ Clients',
    },
    {
      id: 4,
      icon: <FaFilm />,
      title: 'Successful Productions',
      description: 'Worked on numerous high-profile productions, including commercials and films.',
      // stat: '9+ Productions',
    },
  ];

  return (
    <section className="key-achievements">
      <div className="container">
        <h2 className="section-title">Our Key Achievements</h2>
        <div className="achievements-list">
          {milestones.map((milestone) => (
            <div className="achievement-item" key={milestone.id}>
              <div className="achievement-icon">{milestone.icon}</div>
              <div className="achievement-text">
                <h3 className="achievement-title">{milestone.title}</h3>
                <p className="achievement-description">{milestone.description}</p>
                <p className="achievement-stat">{milestone.stat}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyAchievements;
