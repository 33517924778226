import React, { useEffect } from 'react';
import { FaVideo, FaCamera, FaPlane, FaPlus } from 'react-icons/fa';
import './WhatWeDo.css';

const WhatWeDo = () => {
  // Function to handle scroll animation using IntersectionObserver
  useEffect(() => {
    const revealElements = document.querySelectorAll('.service');

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger the callback when 10% of the element is in the viewport
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add 'revealed' class to trigger animation
          entry.target.classList.add('revealed');
        } else {
          // Remove 'revealed' class when the element is out of view to trigger animation again
          entry.target.classList.remove('revealed');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    revealElements.forEach(element => {
      observer.observe(element);
    });

    // Cleanup observer on unmount
    return () => {
      revealElements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className="what-we-do">
      <div className="content">
        <h2>What We Do</h2>
        <div className="services">
          <div className="service">
            <FaVideo className="icon" />
            <h3>Video Productions</h3>
            <p>We create high-quality video content that tells your story effectively.</p>
          </div>
          <div className="service">
            <FaCamera className="icon" />
            <h3>Gear Rental</h3>
            <p>Rent top-notch gear to elevate your production quality.</p>
          </div>
          <div className="service">
            <FaPlane className="icon" />
            <h3>Travel Coordination</h3>
            <p>We handle logistics to ensure smooth travel for your team.</p>
          </div>
          <div className="service">
            <FaPlus className="icon" />
            <h3>And More</h3>
            <p>Explore our additional services tailored to meet your needs.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
