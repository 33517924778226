import React from 'react';
import HeroSection from '../components/ProjectsHero';
import FeaturedProjects from '../components/FeaturedProjects';
import ProjectCategories from '../components/ProjectCategories';
import RecentProjects from '../components/RecentProjcts';
import Testimonials from '../components/Testimonials'
import KeyAchievements from '../components/KeyAchievements'
import WhyChooseUs from '../components/WhyChooseUs'
import HireUsNow from '../components/HireUsNow'
import Footer from '../components/Footer'

const Projects = () => {
  return (
    <div>
      <HeroSection />
      <RecentProjects />
      <FeaturedProjects />
      <ProjectCategories />
      <Testimonials />
      <KeyAchievements />
      <WhyChooseUs />
      <HireUsNow />
      <Footer />
    </div>
  );
};

export default Projects;
