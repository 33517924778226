import React from 'react';
import './OurClients.css';

// Import custom images for the clients
import netflixLogo from '../assets/brands/netflix.png';
import amazonPrimeLogo from '../assets/brands/prime.png';
import tataLogo from '../assets/brands/tata.png';
import ceatLogo from '../assets/brands/ceat.png';
import manyawarLogo from '../assets/brands/manyawar.png';
import campaLogo from '../assets/brands/campa.png';
import excelLogo from '../assets/brands/excel.png';
import airIndiaLogo from '../assets/brands/airIndia.png';
import hyundaiLogo from '../assets/brands/hyundai.png';
import dsGroupLogo from '../assets/brands/ds.png';
import dharmaLogo from '../assets/brands/dharma.png';
import jungleBookLogo from '../assets/brands/jb.jpg';
import viraasiLogo from '../assets/brands/viraasi.png';
import rsvpLogo from '../assets/brands/rsvp.jpg';
import twamevLogo from '../assets/brands/twamev.png';

const OurClients = () => {
  const clients = [
    { name: "Netflix", logo: netflixLogo },
    { name: "Amazon Prime", logo: amazonPrimeLogo },
    { name: "Tata", logo: tataLogo },
    { name: "CEAT", logo: ceatLogo },
    { name: "Manyawar", logo: manyawarLogo },
    { name: "Campa", logo: campaLogo },
    { name: "Excel", logo: excelLogo },
    { name: "Air India", logo: airIndiaLogo },
    { name: "Hyundai", logo: hyundaiLogo },
    { name: "DS Group", logo: dsGroupLogo },
    { name: "Dharma Productions", logo: dharmaLogo },
    { name: "Jungle Book Studio", logo: jungleBookLogo },
    { name: "Viraasi", logo: viraasiLogo },
    { name: "RSVP", logo: rsvpLogo },
    { name: "Twamev", logo: twamevLogo },
  ];

  return (
    <div className="our-clients">
      <h2>Our Clients</h2>
      <div className="client-logos">
        <div className="logos-container">
          {clients.concat(clients).map((client, index) => (
            <div key={index} className="client-logo">
              <img src={client.logo} alt={client.name} className="clogo-img" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;
