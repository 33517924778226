import React from 'react';
import Hero from '../components/Hero';
import WhatWeDo from '../components/WhatWeDo';
import AboutUs from '../components/AboutUs';
import OurClients from '../components/OurClients';
import WhyChooseUs from '../components/WhyChooseUs';
import HireUsNow from '../components/HireUsNow';
import Footer from '../components/Footer';

const Home = () => {
  return (
    <div>
      <Hero />
      <WhatWeDo />
      <AboutUs />
      <OurClients />
      <WhyChooseUs />
      <HireUsNow />
      <Footer />
    </div>
  );
};

export default Home;
