import React from 'react';
import { FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import './MeetTheTeam.css';

import harshitPhoto from '../assets/harshit_goel.png';
import luckyPhoto from '../assets/luckyjingar.jpg';

// Helper function to generate social links
const generateSocialLinks = (instagram, linkedin, email) => ({
  instagram,
  linkedin,
  email,
});

// Team member data
const teamMembers = [
  {
    id: 1,
    name: 'HARSHIT GOEL',
    role: 'FOUNDER & PRODUCER',
    bio: 'At Dreamscape Studios, our vision is to redefine visual storytelling through innovative production. Specializing in line production for films, ads, music videos, and creative shoots, we transform imaginative concepts into captivating realities. Our mission is to push creative boundaries and deliver exceptional, memorable experiences.',
    photo: harshitPhoto,
    socialLinks: generateSocialLinks(
      'https://www.instagram.com/harshitgoel__/',
      'https://www.linkedin.com/in/harshit-goel-89097a224/',
      'mailto:goelharshit0@gmail.com'
    ),
  },
  {
    id: 2,
    name: 'LUCKY JINGAR',
    role: 'CO-FOUNDER & PRODUCER',
    bio: 'Lucky Jingar is the creative force behind the projects at Dreamscape Studios. With a deep passion for storytelling and a knack for producing visually stunning works, Lucky ensures that every project meets the highest standards of excellence.',
    photo: luckyPhoto,
    socialLinks: generateSocialLinks(
      'https://www.instagram.com/lucky__2908/',
      'https://www.linkedin.com/in/lucky-jingar/',
      'mailto:luckyjingar@gmail.com'
    ),
  },
];

// Component to render individual team member
const TeamMember = ({ member }) => (
  <div className="team-member" key={member.id}>
    <img src={member.photo} alt={member.name} className="team-member-photo" />
    <div className="team-member-info">
      <h3 className="team-member-name">{member.name}</h3>
      <p className="team-member-role">{member.role}</p>
      <div className="team-member-social">
        <a href={member.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram className="social-icon" />
        </a>
        <a href={member.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin className="social-icon" />
        </a>
        <a href={member.socialLinks.email} target="_blank" rel="noopener noreferrer">
          <FaEnvelope className="social-icon" />
        </a>
      </div>
      <p className="team-member-bio">{member.bio}</p>
    </div>
  </div>
);

const MeetTheTeam = () => {
  return (
    <section className="meet-the-team">
      <div className="container">
        <h2 className="section-title">Meet Our Team</h2>
        <div className="team-members">
          {teamMembers.map((member) => (
            <TeamMember key={member.id} member={member} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default MeetTheTeam;
