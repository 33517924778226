import React from 'react';
import './TitleSubtitle.css';

const TitleSubtitle = () => {
  return (
    <div className="title-subtitle-container">
      <h1 className="page-title">Our Locations</h1>
      <p className="page-subtitle">Explore our featured locations around the world</p>
    </div>
  );
};

export default TitleSubtitle;
