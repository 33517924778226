import React, { useEffect } from 'react';
import { FaVideo, FaPlane, FaCamera, FaMapMarkerAlt, FaCogs, FaTicketAlt, FaClipboardList, FaUsers } from 'react-icons/fa';
import './AboutUs.css';

const AboutUs = () => {
  // Function to handle scroll animation using IntersectionObserver
  useEffect(() => {
    const revealElements = document.querySelectorAll('.service-card, .service-card h4, .service-card p');

    const options = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger the callback when 10% of the element is in the viewport
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Add 'revealed' class to trigger animation
          entry.target.classList.add('revealed');
        } else {
          // Remove 'revealed' class when the element is out of view to trigger animation again
          entry.target.classList.remove('revealed');
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    revealElements.forEach(element => {
      observer.observe(element);
    });

    // Cleanup observer on unmount
    return () => {
      revealElements.forEach(element => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (
    <div className="about-us">
      <div className="content">
        <h2>About Us</h2>
        <p>
          Dreamscape Studios is a creative powerhouse dedicated to bringing your vision to life. 
          With years of experience in video production, travel coordination, and top-tier gear rental, 
          we specialize in delivering unmatched quality and professionalism.
        </p>
        <p>
          Our team of experts is passionate about storytelling, ensuring every project we undertake 
          captures the essence of your brand. Whether it's a small-scale production or a large event, 
          we adapt to your needs with precision and creativity.
        </p>
        <h3>Our Services</h3>
        <div className="services">
          <div className="service-card">
            <FaVideo className="service-icon" />
            <h4>Video Production</h4>
            <p>High-quality video production tailored to your needs, from concept to final cut.</p>
          </div>
          <div className="service-card">
            <FaPlane className="service-icon" />
            <h4>Travel Coordination</h4>
            <p>Seamless travel coordination for your crew, ensuring every detail is covered.</p>
          </div>
          <div className="service-card">
            <FaCamera className="service-icon" />
            <h4>Equipment Rental</h4>
            <p>Top-of-the-line cameras, lighting, and audio equipment for any production.</p>
          </div>
          <div className="service-card">
            <FaMapMarkerAlt className="service-icon" />
            <h4>Location Scouting</h4>
            <p>Discover the perfect locations that enhance your project’s narrative.</p>
          </div>
          <div className="service-card">
            <FaCogs className="service-icon" />
            <h4>Production Management</h4>
            <p>Efficient management of all aspects of your production, from start to finish.</p>
          </div>
          <div className="service-card">
            <FaClipboardList className="service-icon" />
            <h4>Line Production</h4>
            <p>Professional line production services to keep everything running smoothly.</p>
          </div>
          <div className="service-card">
            <FaTicketAlt className="service-icon" />
            <h4>Permits</h4>
            <p>We handle all the paperwork and permits required for your production.</p>
          </div>
          <div className="service-card">
            <FaUsers className="service-icon" />
            <h4>Casting</h4>
            <p>Connecting you with the perfect talent to bring your project to life.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
