import React from 'react';
import './FeaturedProjects.css';

const FeaturedProjects = () => {
  const projects = [
    {
      id: 1,
      title: 'Twamev',
      description: 'Truly You.',
      image: 'https://img.youtube.com/vi/OFiHoLAAyPw/hqdefault.jpg',
      link: 'https://youtu.be/OFiHoLAAyPw?si=uvvcjrzS5C4dfJUd',
    },
    {
      id: 2,
      title: 'Twamev',
      description: 'A mix of past.',
      image: 'https://img.youtube.com/vi/5TpJN-lGyPU/hqdefault.jpg',
      link: 'https://youtu.be/5TpJN-lGyPU?si=VAJ8N2SC9rSmQiNn',
    },
    {
      id: 3,
      title: 'Twamev',
      description: 'I am grace.',
      image: 'https://img.youtube.com/vi/KjGsKOiznPM/hqdefault.jpg',
      link: 'https://youtu.be/KjGsKOiznPM?si=5czVp0E4fNP73H5y',
    },
    {
      id: 4,
      title: 'Mismatched S3 | Netflix India',
      description: 'Dimple and Rishi are back again, to spread some love with your Favourite Comfort Show! Mismatched S3.',
      image: 'https://img.youtube.com/vi/b87uNYutCLs/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=b87uNYutCLs&ab_channel=NetflixIndia',
    },
    {
      id: 5,
      title: 'Mr. & Mrs. Mahi | Netflix India',
      description: 'Watch Mr. & Mrs. Mahi live their dreams and not their parents’, in this heartfelt, inspiring drama.',
      image: 'https://img.youtube.com/vi/vB7OiVfmEDM/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=vB7OiVfmEDM&ab_channel=NetflixIndia',
    },
    {
      id: 6,
      title: 'Air India New Identity',
      description: 'Reimagining the legacy of Air India with a fresh identity and vision.',
      image: 'https://img.youtube.com/vi/al51WZcN2_U/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=al51WZcN2_U&ab_channel=AirIndiaOfficial',
    },
    {
      id: 7,
      title: 'Tata Motors Cars | Built for the Bold',
      description: 'Explore the bold and innovative designs of Tata Motors cars.',
      image: 'https://img.youtube.com/vi/9QFe0eELKws/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=9QFe0eELKws&ab_channel=TataMotorsCars',
    },
    {
      id: 8,
      title: 'Parachute Advansed | Coconut Hair Oil',
      description: 'Rediscover the timeless beauty of coconut hair oil with Parachute Advansed.',
      image: 'https://img.youtube.com/vi/rJj-HQ3mJvE/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=rJj-HQ3mJvE&ab_channel=ParachuteAdvansed',
    },
    {
      id: 9,
      title: '7 Wonders Music | Calm Symphony',
      description: 'Experience the serene symphonies from 7 Wonders Music.',
      image: 'https://img.youtube.com/vi/yADhUV2GR-0/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=yADhUV2GR-0&ab_channel=7WondersMusic',
    },
    {
      id: 10,
      title: 'Game Theory | Strategic Thinking',
      description: 'Unlock the secrets of strategic thinking with Game Theory.',
      image: 'https://img.youtube.com/vi/xcj_8Y4Uh1k/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=xcj_8Y4Uh1k&ab_channel=GameTheory',
    },
    {
      id: 11,
      title: 'Neelkanth IVF | Parenthood Journey',
      description: 'Join the journey of parenthood with the expertise of Neelkanth IVF.',
      image: 'https://img.youtube.com/vi/tZJTlEJXlYo/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=tZJTlEJXlYo&ab_channel=NeelkanthIVF',
    },
    {
      id: 12,
      title: 'Candi L | Sweet Moments',
      description: 'Cherish life’s sweet moments with Candi L.',
      image: 'https://img.youtube.com/vi/pUjPP6tdYc0/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=pUjPP6tdYc0&ab_channel=CandiL.',
    },
    {
      id: 13,
      title: 'Rajasthan Tourism | Discover Rajasthan',
      description: 'Discover the vibrant culture and heritage of Rajasthan.',
      image: 'https://img.youtube.com/vi/nZP9OL698as/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=nZP9OL698as&ab_channel=RajasthanTourism',
    },
    {
      id: 14,
      title: 'Desi Dutch Originals | Fusion Beats',
      description: 'Explore the fusion of cultures with Desi Dutch Originals.',
      image: 'https://img.youtube.com/vi/qbHW5huTBPE/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=qbHW5huTBPE&ab_channel=DesiDutchOriginals',
    },
  ];


  return (
    <section className="featured-projects">
      <div className="container">
        <h2 className="featured-projects-title">Featured Projects</h2>
        <div className="projects-grid">
          {projects.map((project) => (
            <div className="project-card" key={project.id}>
              <img
                src={project.image}
                alt={project.title}
                className="project-image"
              />
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-description">{project.description}</p>
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="view-project-button"
                >
                  Watch
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProjects;
