import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; 
import logo from '../assets/logo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('/'); // Track the active link
  const menuRef = useRef(null);

  // Toggle the hamburger menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Set active link when a link is clicked
  const handleLinkClick = (path) => {
    setActiveLink(path); // Set the active link
    if (isMenuOpen) {
      setIsMenuOpen(false); // Close the menu if it's open
    }
  };

  // Close the menu if clicked outside
  const handleClickOutside = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setIsMenuOpen(false);  // Close the menu when clicked outside
    }
  };

  // Add event listener for clicking outside the menu
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);  // Cleanup event listener
    };
  }, []);

  return (
    <nav className={`navbar navbar-expand-lg navbar-light navbar-glass sticky-top ${isMenuOpen ? 'menu-open' : ''}`} ref={menuRef}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src={logo} alt="Logo" className="navbar-logo" />
        </Link>

        {/* Hamburger Menu only visible on mobile */}
        <div className="hamburger-mobile" onClick={toggleMenu}>
          <button className={`hamburger ${isMenuOpen ? 'open' : ''}`}>
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
        </div>

        {/* Navbar Links */}
        <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/' ? 'active' : ''}`} 
                to="/" 
                onClick={() => handleLinkClick('/')}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/about' ? 'active' : ''}`} 
                to="/about" 
                onClick={() => handleLinkClick('/about')}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/projects' ? 'active' : ''}`} 
                to="/projects" 
                onClick={() => handleLinkClick('/projects')}
              >
                Projects
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${activeLink === '/locations' ? 'active' : ''}`} 
                to="/locations" 
                onClick={() => handleLinkClick('/locations')}
              >
                Locations
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link contact ${activeLink === '/contact' ? 'active' : ''}`} 
                to="/contact" 
                onClick={() => handleLinkClick('/contact')}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
