import React, { useState, useEffect } from "react";
import {FaLinkedinIn, FaInstagram, FaYoutube, FaArrowUp } from "react-icons/fa";  // Import the icons
import "./Footer.css";

const Footer = () => {
  const [showScrollBtn, setShowScrollBtn] = useState(false);  // State to control button visibility
  const [reveal, setReveal] = useState(false);  // State to trigger animation

  useEffect(() => {
    // Function to check if the footer is in view and trigger reveal animation
    const handleScroll = () => {
      const footer = document.querySelector('.footer-section');
      const rect = footer.getBoundingClientRect();
      
      // Reveal the footer content when it is in view
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setReveal(true);
        setShowScrollBtn(true); 
      } else {
        setReveal(false);
        setShowScrollBtn(false);  
      }
    };

    
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <footer className={`footer-section ${reveal ? 'reveal' : ''}`}>
      <div className="footer-content">
        {/* Branding and Tagline */}
        <div className="footer-branding">
          <h2>Dreamscape Studios</h2>
          <p> a passionate video production company offering full-service </p>
          <p>production, gear rental, and travel coordination.</p>
        </div>

        {/* Contact Info */}
        <div className="footer-contact">
          <h3>Contact</h3>
          <p>Email: <a href="mailto:dreamscapesstudiosrajasthan@gmail.com">dreamscapesstudiosrajasthan@gmail.com</a></p>
          <p>Phone: <a href="tel:+91 7800035658">+91 7800035658</a></p>

          <div className="social-icons">
            <a href="https://www.linkedin.com/in/dreamscape-studios-693970313/" target="_blank" rel="noopener noreferrer">
              <FaLinkedinIn />
            </a>
            <a href="https://www.instagram.com/_dreamscape.studio?igsh=Mjg2MTdvOWxkZXVl" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>
          &copy; 2024 Dreamscape Studios. All Rights Reserved | 
          <a href="https://tirkey.in" target="_blank" rel="noopener noreferrer" className="footer-link"> tirkey.in</a>
        </p>
      </div>

      {/* Show the scroll-to-top button */}
      {showScrollBtn && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          <FaArrowUp />
        </button>
      )}
    </footer>
  );
};

export default Footer;
