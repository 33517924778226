import React from 'react';
import ContactHero from '../components/ContactHero';
import ContactForm from '../components/ContactForm';
// import ContactInfo from '../components/ContactInfo';
// import MapSection from './MapSection';
import Footer from '../components/Footer'

const ContactPage = () => {
  return (
    <div>
      <ContactHero />
      <ContactForm />
      {/* <ContactInfo /> */}
      {/* <MapSection /> */}
      <Footer />
    </div>
  );
};

export default ContactPage;
