import React from 'react';
import './ContactHero.css'; 

const ContactHero = () => {
  return (
    <section className="contact-hero">
      <h1 className="hero-title">Get in Touch</h1>
      <p className="hero-subtitle">We would love to hear from you! Reach out for any inquiries.</p>
    </section>
  );
};

export default ContactHero;
