import React from 'react';
import './Testimonials.css';
import { FaQuoteLeft } from 'react-icons/fa';

const testimonials = [
  {
    id: 1,
    name: '7 Wonders Music',
    role: 'Music Label ',
    photo: 'https://yt3.googleusercontent.com/_N3KApZL3P3VEYkBgWtjEy6qffRNtlDjssWJB5n2eaDx84CKGe0_V18LJdkgZazSbmbfp0KFYQ=s160-c-k-c0x00ffffff-no-rj', 
    quote: 'Dreamscape Studios exceeded our expectations. Their creativity and professionalism made our project come to life!',
  },
  {
    id: 2,
    name: 'Neelkanth IVF',
    role: 'IVF Center - Ajmer',
    photo: 'https://yt3.googleusercontent.com/mUu_YsISdcbBQxLZbYoQV4EzPWLsIu7_j_wtJiNwq7CbJWwnu43ZdGB5fZkMQa-gj71KUolI8g=s160-c-k-c0x00ffffff-no-rj', 
    quote: 'The team at Dreamscape Studios was exceptional. Their attention to detail and dedication is unmatched.',
  },
  {
    id: 3,
    name: 'Game Theory',
    role: 'Youtube',
    photo: 'https://yt3.googleusercontent.com/e5gyQnI0DZQGrkFSu3Jj7WNySAqyC9EBAKfjGsD0ItwJcB88Fh_cZ9rrAf69O6jd7JF4POQslA=s160-c-k-c0x00ffffff-no-rj', 
    quote: 'Working with Dreamscape Studios was a fantastic experience. Their work is top-notch and always delivered on time!',
  },
];

const Testimonials = () => {
  return (
    <section className="testimonials">
      <div className="container">
        <h2 className="section-title">What Our Clients Say</h2>
        <div className="testimonial-cards">
          {testimonials.map((testimonial) => (
            <div className="testimonial-card" key={testimonial.id}>
              <div className="testimonial-quote">
                <FaQuoteLeft className="quote-icon" />
                <p className="quote">{testimonial.quote}</p>
              </div>
              <div className="testimonial-info">
                <img
                  src={testimonial.photo}
                  alt={testimonial.name}
                  className="testimonial-photo"
                />
                <div className="testimonial-details">
                  <p className="testimonial-name">{testimonial.name}</p>
                  <p className="testimonial-role">{testimonial.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
