import React, { useEffect } from 'react';
import './CustomParticleAnimation.css';

const CustomParticleAnimation = () => {
  useEffect(() => {
    const canvas = document.getElementById('particles-canvas');
    const ctx = canvas.getContext('2d');
    const particles = [];
    const maxParticles = 50;

    // Set canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Particle class to define particle properties and behavior
    class Particle {
      constructor(x, y, radius, color, speedX, speedY) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.color = color;
        this.speedX = speedX;
        this.speedY = speedY;
      }

      // Method to update particle's position
      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        // Bounce particles off walls
        if (this.x > canvas.width || this.x < 0) this.speedX = -this.speedX;
        if (this.y > canvas.height || this.y < 0) this.speedY = -this.speedY;
      }

      // Method to draw particle on canvas
      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color;
        ctx.fill();
      }
    }

    // Method to create particles
    function createParticles() {
      for (let i = 0; i < maxParticles; i++) {
        let radius = Math.random() * 3 + 1;
        let x = Math.random() * canvas.width;
        let y = Math.random() * canvas.height;
        let speedX = (Math.random() - 0.5) * 1;
        let speedY = (Math.random() - 0.5) * 1;
        let color = '#fff'; // white color
        particles.push(new Particle(x, y, radius, color, speedX, speedY));
      }
    }

    // Method to draw the lines connecting particles
    function connectParticles() {
      for (let i = 0; i < particles.length; i++) {
        for (let j = i + 1; j < particles.length; j++) {
          let dx = particles[i].x - particles[j].x;
          let dy = particles[i].y - particles[j].y;
          let distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 150) {
            ctx.strokeStyle = `rgba(255, 255, 255, ${1 - distance / 150})`;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(particles[j].x, particles[j].y);
            ctx.stroke();
          }
        }
      }
    }

    // Method to animate the particles and connections
    function animateParticles() {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
      for (let i = 0; i < particles.length; i++) {
        particles[i].update();
        particles[i].draw();
      }
      connectParticles();
      requestAnimationFrame(animateParticles);
    }

    createParticles();
    animateParticles();
  }, []);

  return <canvas id="particles-canvas"></canvas>;
};

export default CustomParticleAnimation;
