import React from 'react';
import { FaVideo, FaCogs, FaCameraRetro } from 'react-icons/fa';
import './AboutUsDetails.css';

const AboutUsDetails = () => {
  const sections = [
    {
      id: 1,
      icon: <FaVideo />,
      title: 'We Are the Best at What We Do',
      description:
        'We are a passionate video production company offering full-service production, gear rental, and travel coordination. We bring your creative vision to life from concept to completion.',
    },
    {
      id: 2,
      icon: <FaCogs />,
      title: 'What We Do',
      description:
        "Dreamscape Studios provides flexible production support. Whether it's full project management or a specific part like casting or on-set support, we handle it all. For photographers, agencies, and production companies, we offer the best talent, local expertise, and a seamless experience.",
    },
    {
      id: 3,
      icon: <FaCameraRetro />,
      title: 'Our Work',
      description:
        'We specialize in product, fashion, and lifestyle photography, as well as Television Commercials and Digital Films. Our work is known for compelling visual imagery, supported by the best equipment and talented professionals. We have worked with brands like Tata Harrier, Air India, and facilitated international productions like Amazing Race.',
    },
  ];

  return (
    <section className="about-us-details-section">
      <div className="about-us-container">
        <h2 className="about-us-section-title">About Dreamscape Studios</h2>
        <div className="about-us-sections">
          {sections.map((section) => (
            <div className="about-us-section-item" key={section.id}>
              <div className="about-us-icon">{section.icon}</div>
              <div className="about-us-text-content">
                <h3 className="about-us-section-title">{section.title}</h3>
                <p className="about-us-section-description">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUsDetails;
