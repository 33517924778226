import React, { useState } from 'react';
import './ProjectCategories.css';

const ProjectCategories = () => {
  const categories = [
    'All',
    'Commercials',
    'Photography',
    'Digital Films',
    'Brand Campaigns',
  ];

  const projects = [
    {
      id: 1,
      category: 'Commercials',
      title: 'Twamev - Truly You',
      image: 'https://img.youtube.com/vi/OFiHoLAAyPw/hqdefault.jpg',
      link: 'https://youtu.be/OFiHoLAAyPw', // Direct YouTube link
    },
    {
      id: 2,
      category: 'Commercials',
      title: 'Twamev - A mix of past',
      image: 'https://img.youtube.com/vi/5TpJN-lGyPU/hqdefault.jpg',
      link: 'https://youtu.be/5TpJN-lGyPU', 
    },
    {
      id: 3,
      category: 'Commercials',
      title: 'Twamev - I am grace',
      image: 'https://img.youtube.com/vi/KjGsKOiznPM/hqdefault.jpg',
      link: 'https://youtu.be/KjGsKOiznPM', 
    },
    {
      id: 4,
      category: 'Digital Films',
      title: 'Mismatched S3',
      image: 'https://img.youtube.com/vi/b87uNYutCLs/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=b87uNYutCLs&ab_channel=NetflixIndia',
    },
    {
      id: 5,
      category: 'Digital Films',
      title: 'Mr. & Mrs. Mahi',
      image: 'https://img.youtube.com/vi/vB7OiVfmEDM/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=vB7OiVfmEDM&ab_channel=NetflixIndia',
    },
    {
      id: 6,
      category: 'Commercials',
      title: 'Air India New Identity',
      image: 'https://img.youtube.com/vi/al51WZcN2_U/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=al51WZcN2_U&ab_channel=AirIndiaOfficial',
    },
    {
      id: 7,
      category: 'Commercials',
      title: 'Tata Motors Cars Campaign',
      image: 'https://img.youtube.com/vi/9QFe0eELKws/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=9QFe0eELKws&ab_channel=TataMotorsCars',
    },
    {
      id: 8,
      category: 'Commercials',
      title: 'Parachute Advansed Coconut Oil',
      image: 'https://img.youtube.com/vi/rJj-HQ3mJvE/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=rJj-HQ3mJvE&ab_channel=ParachuteAdvansed',
    },
    {
      id: 9,
      category: 'Photography',
      title: '7 Wonders Music Symphony',
      image: 'https://img.youtube.com/vi/yADhUV2GR-0/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=yADhUV2GR-0&ab_channel=7WondersMusic',
    },
    {
      id: 10,
      category: 'Brand Campaigns',
      title: 'Game Theory | Strategic Thinking',
      image: 'https://img.youtube.com/vi/xcj_8Y4Uh1k/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=xcj_8Y4Uh1k&ab_channel=GameTheory',
    },
    {
      id: 11,
      category: 'Brand Campaigns',
      title: 'Neelkanth IVF',
      image: 'https://img.youtube.com/vi/tZJTlEJXlYo/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=tZJTlEJXlYo&ab_channel=NeelkanthIVF',
    },
    {
      id: 12,
      category: 'Photography',
      title: 'Candi L Sweet Moments',
      image: 'https://img.youtube.com/vi/pUjPP6tdYc0/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=pUjPP6tdYc0&ab_channel=CandiL.',
    },
    {
      id: 13,
      category: 'Brand Campaigns',
      title: 'Rajasthan Tourism Promo',
      image: 'https://img.youtube.com/vi/nZP9OL698as/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=nZP9OL698as&ab_channel=RajasthanTourism',
    },
    {
      id: 14,
      category: 'Photography',
      title: 'Desi Dutch Originals Fusion Beats',
      image: 'https://img.youtube.com/vi/qbHW5huTBPE/hqdefault.jpg',
      link: 'https://www.youtube.com/watch?v=qbHW5huTBPE&ab_channel=DesiDutchOriginals',
    },
  ];

  const [activeCategory, setActiveCategory] = useState('All');

  const filteredProjects =
    activeCategory === 'All'
      ? projects
      : projects.filter((project) => project.category === activeCategory);

  return (
    <section className="project-categories">
      <div className="container">
        <h2 className="categories-title">Project Categories</h2>
        <div className="categories-filter">
          {categories.map((category) => (
            <button
              key={category}
              className={`filter-button ${activeCategory === category ? 'active' : ''}`}
              onClick={() => setActiveCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
        <div className="projects-grid">
          {filteredProjects.map((project) => (
            <div
              className="project-card"
              key={project.id}
              onClick={() => window.open(project.link, '_blank')} 
            >
              <img src={project.image} alt={project.title} className="project-image" />
              <h3 className="project-title">{project.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectCategories;
