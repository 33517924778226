import React from 'react';
import './HeroSection.css';
import CustomParticleAnimation from './CustomParticleAnimation'; 

const HeroSection = () => {
  const handleButtonClick = () => {
    
  };

  return (
    <div className="hero-section">
      {/* Custom particle animation container placed as background */}
      <div className="hero-background">
        <CustomParticleAnimation />
      </div>

      <div className="content">
        <h1 className="title">Our Portfolio</h1>
        <p className="subtitle">Discover our projects and works experiences</p>
        {/* <button className="projects-button" onClick={handleButtonClick}>
          View Projects
        </button> */}
      </div>
    </div>
  );
};

export default HeroSection;
