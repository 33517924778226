import React from 'react';
import './HeroSection.css';
import CustomParticleAnimation from './CustomParticleAnimation'; 

const HeroSection = () => {
  const handleButtonClick = () => {
    // Replace with the actual link to the projects page
    window.location.href = '/projects';
  };

  return (
    <div className="hero-section">
      <div className="content">
        <h1 className="title">We are best at what we do</h1>
        <p className="subtitle">We are a dedicated video production company offering full-service production, gear rental, and travel coordination to bring your creative vision to life.</p>
        <button className="projects-button" onClick={handleButtonClick}>
          View Projects
        </button>
      </div>
      
      {/* Custom particle animation container placed below the content */}
      <div className="particle-animation-container">
        <CustomParticleAnimation />
      </div>
    </div>
  );
};

export default HeroSection;
