import React, { useState } from 'react';
import emailjs from 'emailjs-com'; 
import './HireUsNow.css';

const HireUsNow = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [isSending, setIsSending] = useState(false); 
  const [successMessage, setSuccessMessage] = useState(''); 
  const [errorMessage, setErrorMessage] = useState(''); 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Send the form data via EmailJS
    emailjs
      .send(
        'service_vxwn36b', 
        'template_c4ijlpa', 
        formData, 
        'XcCeV_wJ7sNL9q4V-' 
      )
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setSuccessMessage('Your message has been sent successfully!');
          setFormData({ name: '', email: '', message: '' }); 
        },
        (error) => {
          console.error('FAILED...', error);
          setErrorMessage('Failed to send your message. Please try again later.');
        }
      )
      .finally(() => setIsSending(false));
  };

  return (
    <div className="hire-us-now">
      <div className="hire-us-content">
        <h2>Hire Us Now</h2>
        <p>We are ready to bring your vision to life. Fill in the form below to get started!</p>

        {/* Success or Error Message */}
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <form onSubmit={handleSubmit} className="hire-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder="Tell us about your project"
              required
            />
          </div>
          <button type="submit" className="cta-button" disabled={isSending}>
            {isSending ? 'Sending...' : 'Send Message'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HireUsNow;
