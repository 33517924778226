import React, { useState } from 'react';
import './Locations.css';

// Import images from src/assets/locations folder
import albertHallImg1 from '../assets/locations/AlbertHall/image (1).jpg';
import albertHallImg2 from '../assets/locations/AlbertHall/image (2).jpg';

import albertHallImg10 from '../assets/locations/AlbertHall/image (10).jpg';
import albertHallImg11 from '../assets/locations/AlbertHall/image (11).jpg';
import albertHallImg12 from '../assets/locations/AlbertHall/image (12).jpg';

// DundotFort
import dundotFortImg1 from '../assets/locations/DundotFort/image (1).jpg';
import dundotFortImg2 from '../assets/locations/DundotFort/image (2).jpg';
import dundotFortImg10 from '../assets/locations/DundotFort/image (10).jpg';
import dundotFortImg11 from '../assets/locations/DundotFort/image (11).jpg';
import dundotFortImg12 from '../assets/locations/DundotFort/image (12).jpg';
import dundotFortImg13 from '../assets/locations/DundotFort/image (13).jpg';
import dundotFortImg14 from '../assets/locations/DundotFort/image (14).jpg';
import dundotFortImg15 from '../assets/locations/DundotFort/image (15).jpg';
import dundotFortImg16 from '../assets/locations/DundotFort/image (16).jpg';
import dundotFortImg17 from '../assets/locations/DundotFort/image (17).jpg';
import dundotFortImg18 from '../assets/locations/DundotFort/image (18).jpg';
import dundotFortImg19 from '../assets/locations/DundotFort/image (19).jpg';
import dundotFortImg20 from '../assets/locations/DundotFort/image (20).jpg';
import dundotFortImg21 from '../assets/locations/DundotFort/image (21).jpg';



// NawaLehk
import nawaLehkImg1 from '../assets/locations/NawaLake/image (1).jpg';
import nawaLehkImg2 from '../assets/locations/NawaLake/image (2).jpg';

import nawaLehkImg10 from '../assets/locations/NawaLake/image (10).jpg';
import nawaLehkImg11 from '../assets/locations/NawaLake/image (11).jpg';
import nawaLehkImg12 from '../assets/locations/NawaLake/image (12).jpg';
import nawaLehkImg13 from '../assets/locations/NawaLake/image (13).jpg';
import nawaLehkImg14 from '../assets/locations/NawaLake/image (14).jpg';




// NawalghrHaveli


import nawalghrHaveliImg10 from '../assets/locations/NawalghrHaveli/image (10).jpg';
import nawalghrHaveliImg11 from '../assets/locations/NawalghrHaveli/image (11).jpg';
import nawalghrHaveliImg12 from '../assets/locations/NawalghrHaveli/image (12).jpg';
import nawalghrHaveliImg13 from '../assets/locations/NawalghrHaveli/image (13).jpg';
import nawalghrHaveliImg14 from '../assets/locations/NawalghrHaveli/image (14).jpg';
import nawalghrHaveliImg15 from '../assets/locations/NawalghrHaveli/image (15).jpg';




// Ramghard
import ramghard1 from '../assets/locations/RAMGHAR/image (1).jpg'
import ramghard10 from '../assets/locations/RAMGHAR/image (10).jpg'
import ramghard11 from '../assets/locations/RAMGHAR/image (11).JPG'
import ramghard12 from '../assets/locations/RAMGHAR/image (12).jpg'
import ramghard13 from '../assets/locations/RAMGHAR/image (13).jpg'



// Sambhar S1 - Import images
import sambharS1Img1 from '../assets/locations/SAMBHER S1/image (1).jpg';
import sambharS1Img10 from '../assets/locations/SAMBHER S1/image (10).jpg';
import sambharS1Img11 from '../assets/locations/SAMBHER S1/image (11).jpg';
import sambharS1Img12 from '../assets/locations/SAMBHER S1/image (12).jpg';
import sambharS1Img13 from '../assets/locations/SAMBHER S1/image (13).jpg';
import sambharS1Img14 from '../assets/locations/SAMBHER S1/image (14).jpg';





// Sambhar S2
import sambharS2Img1 from '../assets/locations/SAMBHER S2/image (1).jpg';
import sambharS2Img2 from '../assets/locations/SAMBHER S2/image (2).jpg';

import sambharS2Img10 from '../assets/locations/SAMBHER S2/image (10).jpg';
import sambharS2Img11 from '../assets/locations/SAMBHER S2/image (11).jpg';
import sambharS2Img12 from '../assets/locations/SAMBHER S2/image (12).jpg';
import sambharS2Img13 from '../assets/locations/SAMBHER S2/image (13).jpg';
import sambharS2Img14 from '../assets/locations/SAMBHER S2/image (14).jpg';
import sambharS2Img15 from '../assets/locations/SAMBHER S2/image (15).jpg';
import sambharS2Img16 from '../assets/locations/SAMBHER S2/image (16).jpg';
import sambharS2Img17 from '../assets/locations/SAMBHER S2/image (17).jpg';
import sambharS2Img18 from '../assets/locations/SAMBHER S2/image (18).jpg';
import sambharS2Img19 from '../assets/locations/SAMBHER S2/image (19).jpg';
import sambharS2Img20 from '../assets/locations/SAMBHER S2/image (20).jpg';
import sambharS2Img21 from '../assets/locations/SAMBHER S2/image (21).jpg';
import sambharS2Img22 from '../assets/locations/SAMBHER S2/image (22).jpg';




// Shiv Vilash
import shivVilashImg1 from '../assets/locations/SAMBHER S2/image (23).jpg';
// import shivVilashImg2 from '../assets/locations/ShivVilash/image (2).jpg';
// import shivVilashImg3 from '../assets/locations/ShivVilash/image (3).jpg';

// Vijay ran
import vijayRanImg1 from '../assets/locations/VIJAY RAN PALACE/image (1).jpg';

import vijayRanImg10 from '../assets/locations/VIJAY RAN PALACE/image (10).JPG';
import vijayRanImg11 from '../assets/locations/VIJAY RAN PALACE/image (11).JPG';
import vijayRanImg12 from '../assets/locations/VIJAY RAN PALACE/image (12).JPG';
import vijayRanImg13 from '../assets/locations/VIJAY RAN PALACE/image (13).JPG';



// Kash Bhag
import kashBhagImg1 from '../assets/locations/VIJAY RAN PALACE/image (14).jpg';
// import kashBhagImg2 from '../assets/locations/KashBhag/image (2).jpg';
// import kashBhagImg3 from '../assets/locations/KashBhag/image (3).jpg';

// Haveli 4 Dar Waja
import haveli4DarWajaImg1 from '../assets/locations/CHAR DARWAJA HAVELI/image (1).JPG';

import haveli4DarWajaImg10 from '../assets/locations/CHAR DARWAJA HAVELI/image (10).JPG';
import haveli4DarWajaImg11 from '../assets/locations/CHAR DARWAJA HAVELI/image (11).JPG';
import haveli4DarWajaImg12 from '../assets/locations/CHAR DARWAJA HAVELI/image (12).JPG';
import haveli4DarWajaImg13 from '../assets/locations/CHAR DARWAJA HAVELI/image (13).JPG';
import haveli4DarWajaImg14 from '../assets/locations/CHAR DARWAJA HAVELI/image (14).JPG';


// Alsisar Jaipur
// import alsisarJaipurImg1 from '../assets/locations/ALSISER JAIPUR/image (1).jpg';

import alsisarJaipurImg10 from '../assets/locations/ALSISER JAIPUR/image (10).jpg';
import alsisarJaipurImg11 from '../assets/locations/ALSISER JAIPUR/image (11).jpg';


// Alsisar Sawaimadupur
import alsisarSawaimadupurImg1 from '../assets/locations/ALSISER RANTHMBOR/image (1).jpg';
import alsisarSawaimadupurImg2 from '../assets/locations/ALSISER RANTHMBOR/image (2).jpg';

import alsisarSawaimadupurImg10 from '../assets/locations/ALSISER RANTHMBOR/image (10).jpg';
import alsisarSawaimadupurImg11 from '../assets/locations/ALSISER RANTHMBOR/image (11).jpg';
import alsisarSawaimadupurImg12 from '../assets/locations/ALSISER RANTHMBOR/image (12).jpg';
import alsisarSawaimadupurImg13 from '../assets/locations/ALSISER RANTHMBOR/image (13).jpg';
import alsisarSawaimadupurImg14 from '../assets/locations/ALSISER RANTHMBOR/image (14).jpg';
import alsisarSawaimadupurImg15 from '../assets/locations/ALSISER RANTHMBOR/image (15).jpg';
import alsisarSawaimadupurImg16 from '../assets/locations/ALSISER RANTHMBOR/image (16).jpg';
import alsisarSawaimadupurImg17 from '../assets/locations/ALSISER RANTHMBOR/image (17).jpg';
import alsisarSawaimadupurImg18 from '../assets/locations/ALSISER RANTHMBOR/image (18).jpg';
import alsisarSawaimadupurImg19 from '../assets/locations/ALSISER RANTHMBOR/image (19).jpg';
import alsisarSawaimadupurImg20 from '../assets/locations/ALSISER RANTHMBOR/image (20).jpg';
import alsisarSawaimadupurImg21 from '../assets/locations/ALSISER RANTHMBOR/image (21).jpg';
import alsisarSawaimadupurImg22 from '../assets/locations/ALSISER RANTHMBOR/image (22).jpg';
import alsisarSawaimadupurImg23 from '../assets/locations/ALSISER RANTHMBOR/image (23).jpg';
import alsisarSawaimadupurImg24 from '../assets/locations/ALSISER RANTHMBOR/image (24).jpg';

// import alsisarSawaimadupurImg100 from '../assets/locations/ALSISER RANTHMBOR/image (100).jpg';
// import alsisarSawaimadupurImg101 from '../assets/locations/ALSISER RANTHMBOR/image (101).jpg';


// Alsisar Shekawati
// import alsisarShekawatiImg1 from '../assets/locations/ALSISER SHEKHAWATI/image (1).jpg';

import alsisarShekawatiImg10 from '../assets/locations/ALSISER SHEKHAWATI/image (10).jpg';
import alsisarShekawatiImg11 from '../assets/locations/ALSISER SHEKHAWATI/image (11).jpg';
import alsisarShekawatiImg12 from '../assets/locations/ALSISER SHEKHAWATI/image (12).jpg';
import alsisarShekawatiImg13 from '../assets/locations/ALSISER SHEKHAWATI/image (13).jpg';
import alsisarShekawatiImg14 from '../assets/locations/ALSISER SHEKHAWATI/image (14).jpg';




// import alsisarShekawatiImg102 from '../assets/locations/ALSISER SHEKHAWATI/image (102).jpg';
// import alsisarShekawatiImg103 from '../assets/locations/ALSISER SHEKHAWATI/image (103).jpg';
// import alsisarShekawatiImg104 from '../assets/locations/ALSISER SHEKHAWATI/image (104).jpg';


// RASS Raj Mahel
import rassRajMahelImg1 from '../assets/locations/RASS THE ROYAL HOTEL/image (1).jpg';
import rassRajMahelImg2 from '../assets/locations/RASS THE ROYAL HOTEL/image (2).jpg';

import rassRajMahelImg10 from '../assets/locations/RASS THE ROYAL HOTEL/image (10).jpg';
import rassRajMahelImg11 from '../assets/locations/RASS THE ROYAL HOTEL/image (11).jpg';
import rassRajMahelImg12 from '../assets/locations/RASS THE ROYAL HOTEL/image (12).jpg';
import rassRajMahelImg13 from '../assets/locations/RASS THE ROYAL HOTEL/image (13).jpg';
import rassRajMahelImg14 from '../assets/locations/RASS THE ROYAL HOTEL/image (14).jpg';
import rassRajMahelImg15 from '../assets/locations/RASS THE ROYAL HOTEL/image (15).jpg';
import rassRajMahelImg16 from '../assets/locations/RASS THE ROYAL HOTEL/image (16).jpg';
import rassRajMahelImg17 from '../assets/locations/RASS THE ROYAL HOTEL/image (17).jpg';
import rassRajMahelImg18 from '../assets/locations/RASS THE ROYAL HOTEL/image (18).jpg';
import rassRajMahelImg19 from '../assets/locations/RASS THE ROYAL HOTEL/image (19).jpg';


// import rassRajMahelImg137 from '../assets/locations/RASS THE ROYAL HOTEL/image (137).jpg';
// import rassRajMahelImg138 from '../assets/locations/RASS THE ROYAL HOTEL/image (138).jpg';
// import rassRajMahelImg139 from '../assets/locations/RASS THE ROYAL HOTEL/image (139).jpg';
// import rassRajMahelImg140 from '../assets/locations/RASS THE ROYAL HOTEL/image (140).jpg';
// import rassRajMahelImg141 from '../assets/locations/RASS THE ROYAL HOTEL/image (141).jpg';
// import rassRajMahelImg142 from '../assets/locations/RASS THE ROYAL HOTEL/image (142).jpg';
// import rassRajMahelImg143 from '../assets/locations/RASS THE ROYAL HOTEL/image (143).jpg';
// import rassRajMahelImg144 from '../assets/locations/RASS THE ROYAL HOTEL/image (144).jpg';
// import rassRajMahelImg145 from '../assets/locations/RASS THE ROYAL HOTEL/image (145).jpg';
// import rassRajMahelImg146 from '../assets/locations/RASS THE ROYAL HOTEL/image (146).jpg';
// import rassRajMahelImg147 from '../assets/locations/RASS THE ROYAL HOTEL/image (147).jpg';
// import rassRajMahelImg148 from '../assets/locations/RASS THE ROYAL HOTEL/image (148).jpg';
// import rassRajMahelImg149 from '../assets/locations/RASS THE ROYAL HOTEL/image (149).jpg';
// import rassRajMahelImg150 from '../assets/locations/RASS THE ROYAL HOTEL/image (150).jpg';
// import rassRajMahelImg151 from '../assets/locations/RASS THE ROYAL HOTEL/image (151).jpg';
// import rassRajMahelImg152 from '../assets/locations/RASS THE ROYAL HOTEL/image (152).jpg';


// Amer fort
import amerFortImg1 from '../assets/locations/AMBER FORT/image (1).jpg';
import amerFortImg2 from '../assets/locations/AMBER FORT/image (2).jpg';

import amerFortImg10 from '../assets/locations/AMBER FORT/image (10).jpg';
import amerFortImg11 from '../assets/locations/AMBER FORT/image (11).jpg';
import amerFortImg12 from '../assets/locations/AMBER FORT/image (12).jpg';
import amerFortImg13 from '../assets/locations/AMBER FORT/image (13).jpg';
import amerFortImg14 from '../assets/locations/AMBER FORT/image (14).jpg';
import amerFortImg15 from '../assets/locations/AMBER FORT/image (15).jpg';
import amerFortImg16 from '../assets/locations/AMBER FORT/image (16).jpg';
import amerFortImg17 from '../assets/locations/AMBER FORT/image (17).jpg';
import amerFortImg18 from '../assets/locations/AMBER FORT/image (18).jpg';
import amerFortImg19 from '../assets/locations/AMBER FORT/image (19).jpg';
import amerFortImg20 from '../assets/locations/AMBER FORT/image (20).jpg';
import amerFortImg21 from '../assets/locations/AMBER FORT/image (21).jpg';
import amerFortImg22 from '../assets/locations/AMBER FORT/image (22).jpg';
import amerFortImg23 from '../assets/locations/AMBER FORT/image (23).jpg';
import amerFortImg24 from '../assets/locations/AMBER FORT/image (24).jpg';


// Narghar Fort
import nargharFortImg1 from '../assets/locations/NHARGHAR FORT/image (1).jpg';

import nargharFortImg10 from '../assets/locations/NHARGHAR FORT/image (10).jpg';
import nargharFortImg11 from '../assets/locations/NHARGHAR FORT/image (11).jpg';
import nargharFortImg12 from '../assets/locations/NHARGHAR FORT/image (12).jpg';
import nargharFortImg13 from '../assets/locations/NHARGHAR FORT/image (13).jpg';
import nargharFortImg14 from '../assets/locations/NHARGHAR FORT/image (14).jpg';

// Sisodiya Bagh
import sisodiyaBaghImg1 from '../assets/locations/SISODIYA BAGH/image (1).jpg';

import sisodiyaBaghImg10 from '../assets/locations/SISODIYA BAGH/image (10).jpg';
import sisodiyaBaghImg11 from '../assets/locations/SISODIYA BAGH/image (11).jpg';
import sisodiyaBaghImg12 from '../assets/locations/SISODIYA BAGH/image (12).jpg';
import sisodiyaBaghImg13 from '../assets/locations/SISODIYA BAGH/image (13).jpg';
import sisodiyaBaghImg14 from '../assets/locations/SISODIYA BAGH/image (14).jpg';

// Blue City
import blueCityImg1 from '../assets/locations/Blue city with home/image (1).jpg';
import blueCityImg2 from '../assets/locations/Blue city with home/image (2).jpg';
import blueCityImg3 from '../assets/locations/Blue city with home/image (4).jpg';
import blueCityImg5 from '../assets/locations/Blue city with home/image (5).jpg';
import blueCityImg6 from '../assets/locations/Blue city with home/image (6).jpg';
import blueCityImg7 from '../assets/locations/Blue city with home/image (7).jpg';
import blueCityImg8 from '../assets/locations/Blue city with home/image (8).jpg';
import blueCityImg9 from '../assets/locations/Blue city with home/image (9).jpg';
import blueCityImg10 from '../assets/locations/Blue city with home/image (10).jpg';
import blueCityImg11 from '../assets/locations/Blue city with home/image (11).jpg';
import blueCityImg12 from '../assets/locations/Blue city with home/image (12).jpg';
import blueCityImg13 from '../assets/locations/Blue city with home/image (13).jpg';
import blueCityImg14 from '../assets/locations/Blue city with home/image (14).jpg';


// Chartiya


import chartiyaImg10 from '../assets/locations/Chatriyaa/image (10).jpg';
import chartiyaImg11 from '../assets/locations/Chatriyaa/image (11).jpg';
import chartiyaImg12 from '../assets/locations/Chatriyaa/image (12).jpg';
import chartiyaImg13 from '../assets/locations/Chatriyaa/image (13).jpg';



// Khimwasar Fort

import khimwasarFortImg10 from '../assets/locations/Khiwansar fort/image (10).jpg';
import khimwasarFortImg11 from '../assets/locations/Khiwansar fort/image (11).jpg';
import khimwasarFortImg12 from '../assets/locations/Khiwansar fort/image (12).jpg';
import khimwasarFortImg13 from '../assets/locations/Khiwansar fort/image (13).jpg';


// Mehran Gahar Fort

import mehranGaharFortImg10 from '../assets/locations/Mehrangarh fort/image (10).jpg';
import mehranGaharFortImg11 from '../assets/locations/Mehrangarh fort/image (11).jpg';
import mehranGaharFortImg12 from '../assets/locations/Mehrangarh fort/image (12).jpg';
import mehranGaharFortImg13 from '../assets/locations/Mehrangarh fort/image (13).jpg';
import mehranGaharFortImg14 from '../assets/locations/Mehrangarh fort/image (14).jpg';

import mehranGaharFortImg100 from '../assets/locations/Mehrangarh fort/image (100).jpg';
import mehranGaharFortImg101 from '../assets/locations/Mehrangarh fort/image (101).jpg';
import mehranGaharFortImg102 from '../assets/locations/Mehrangarh fort/image (102).jpg';
import mehranGaharFortImg103 from '../assets/locations/Mehrangarh fort/image (103).jpg';
import mehranGaharFortImg104 from '../assets/locations/Mehrangarh fort/image (104).jpg';
import mehranGaharFortImg105 from '../assets/locations/Mehrangarh fort/image (105).jpg';
import mehranGaharFortImg106 from '../assets/locations/Mehrangarh fort/image (106).jpg';
import mehranGaharFortImg107 from '../assets/locations/Mehrangarh fort/image (107).jpg';
import mehranGaharFortImg108 from '../assets/locations/Mehrangarh fort/image (108).jpg';
import mehranGaharFortImg109 from '../assets/locations/Mehrangarh fort/image (109).jpg';
import mehranGaharFortImg110 from '../assets/locations/Mehrangarh fort/image (110).jpg';
import mehranGaharFortImg111 from '../assets/locations/Mehrangarh fort/image (111).jpg';
import mehranGaharFortImg112 from '../assets/locations/Mehrangarh fort/image (112).jpg';
import mehranGaharFortImg113 from '../assets/locations/Mehrangarh fort/image (113).jpg';
import mehranGaharFortImg114 from '../assets/locations/Mehrangarh fort/image (114).jpg';
import mehranGaharFortImg115 from '../assets/locations/Mehrangarh fort/image (115).jpg';
import mehranGaharFortImg116 from '../assets/locations/Mehrangarh fort/image (116).jpg';
import mehranGaharFortImg117 from '../assets/locations/Mehrangarh fort/image (117).jpg';
import mehranGaharFortImg118 from '../assets/locations/Mehrangarh fort/image (118).jpg';
import mehranGaharFortImg119 from '../assets/locations/Mehrangarh fort/image (119).jpg';
import mehranGaharFortImg120 from '../assets/locations/Mehrangarh fort/image (120).jpg';
import mehranGaharFortImg121 from '../assets/locations/Mehrangarh fort/image (121).jpg';
import mehranGaharFortImg122 from '../assets/locations/Mehrangarh fort/image (122).jpg';
import mehranGaharFortImg123 from '../assets/locations/Mehrangarh fort/image (123).jpg';
import mehranGaharFortImg124 from '../assets/locations/Mehrangarh fort/image (124).jpg';
import mehranGaharFortImg125 from '../assets/locations/Mehrangarh fort/image (125).jpg';
import mehranGaharFortImg126 from '../assets/locations/Mehrangarh fort/image (126).jpg';
import mehranGaharFortImg127 from '../assets/locations/Mehrangarh fort/image (127).jpg';
import mehranGaharFortImg128 from '../assets/locations/Mehrangarh fort/image (128).jpg';
import mehranGaharFortImg129 from '../assets/locations/Mehrangarh fort/image (129).jpg';
import mehranGaharFortImg130 from '../assets/locations/Mehrangarh fort/image (130).jpg';
import mehranGaharFortImg131 from '../assets/locations/Mehrangarh fort/image (131).jpg';
import mehranGaharFortImg132 from '../assets/locations/Mehrangarh fort/image (132).jpg';
import mehranGaharFortImg133 from '../assets/locations/Mehrangarh fort/image (133).jpg';
import mehranGaharFortImg134 from '../assets/locations/Mehrangarh fort/image (134).jpg';
import mehranGaharFortImg135 from '../assets/locations/Mehrangarh fort/image (135).jpg';
import mehranGaharFortImg136 from '../assets/locations/Mehrangarh fort/image (136).jpg';
import mehranGaharFortImg137 from '../assets/locations/Mehrangarh fort/image (137).jpg';
import mehranGaharFortImg138 from '../assets/locations/Mehrangarh fort/image (138).jpg';


// Jawai
import jawaiImg1 from '../assets/locations/JAWAI/image (1).jpg';

import jawaiImg10 from '../assets/locations/JAWAI/image (10).jpg';
import jawaiImg11 from '../assets/locations/JAWAI/image (11).jpg';
import jawaiImg12 from '../assets/locations/JAWAI/image (12).jpg';
import jawaiImg13 from '../assets/locations/JAWAI/image (13).jpg';
import jawaiImg14 from '../assets/locations/JAWAI/image (14).jpg';


// Ghats
import ghatsImg1 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (1).jpg';

import ghatsImg10 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (10).jpg';
import ghatsImg11 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (11).jpg';
import ghatsImg12 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (12).jpg';
import ghatsImg13 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (13).jpg';
import ghatsImg14 from '../assets/locations/Ghats/Lake Pichola (Dudh Talai)/image (14).jpg';


// Street
import streetImg1 from '../assets/locations/Ghats/Rovniya  RAM GHAT Ghat/image (1).jpeg';


const Locations = () => {
  const categories = [
    'All',
    // 'Albert Hall',
    'Location 1',
    // 'Dundot Fort',
    'Location 2',
    // 'Nawa Lehk',
    'Location 3',
    // 'Nawalghr Haveli',
    'Location 4',
    // 'Ramghard',
    'Location 5',
    // 'Sambhar S1',
    'Location 6',
    // 'Sambhar S2',
    'Location 7',
    // 'Shiv Vilash',
    'Location 8',
    // 'Vijay ran',
    'Location 9',
    // 'Kash Bhag',
    'Location 10',
    // 'Haveli 4 Dar Waja',
    'Location 11',
    // 'Alsisar Jaipur',
    'Location 12',
    // 'Alsisar Sawaimadupur',
    'Location 13',
    // 'Alsisar Shekawati',
    'Location 14',
    // 'RASS Raj Mahel',
    'Location 15',
    // 'Amer fort',
    'Location 16',
    // 'Narghar Fort',
    'Location 17',
    // 'Sisodiya Bagh',
    'Location 18',
    // 'Blue City',
    'Location 19',
    // 'Chartiya',
    'Location 20',
    // 'Khimwasar Fort',
    'Location 21',
    // 'Mehran Gahar Fort',
    'Location 22',
    // 'Jawai',
    'Location 23',
    // 'Ghats',
    'Location 24',
    // 'Street',
    'Location 25',
  ];



  const locations = [
    // 'Albert Hall'
    {
      id: 1,
      category: 'Location 1',
      title: 'Location 1',
      images: [albertHallImg1, albertHallImg2, 
        // albertHallImg3, albertHallImg4, albertHallImg5, albertHallImg6, albertHallImg7, albertHallImg8, albertHallImg9, 
        albertHallImg10, albertHallImg11, albertHallImg12],
    },
    // 'Dundot Fort'
    {
      id: 2,
      category: 'Location 2',
      title: 'Location 2',
      images: [dundotFortImg1, dundotFortImg2, dundotFortImg10, dundotFortImg11, dundotFortImg12, dundotFortImg13, dundotFortImg14,dundotFortImg15, dundotFortImg16, dundotFortImg17, dundotFortImg18, dundotFortImg19, dundotFortImg20, dundotFortImg21],
    },
    // 'Nawa Lehk'
    {
      id: 3,
      category: 'Location 3',
      title: 'Location 3',
      images: [nawaLehkImg1, nawaLehkImg2, nawaLehkImg10, nawaLehkImg11, nawaLehkImg12, nawaLehkImg13, nawaLehkImg14],
    },
    // 'Nawalghr Haveli'
    {
      id: 4,
      category: 'Location 4',
      title: 'Location 4',
      images: [nawalghrHaveliImg10, nawalghrHaveliImg11, nawalghrHaveliImg12, nawalghrHaveliImg13, nawalghrHaveliImg14, nawalghrHaveliImg15],
    },
    // 'Ramghard'
    {
      id: 5,
      category: 'Location 5',
      title: 'Location 5',
      images: [ramghard1, ramghard10,ramghard11, ramghard12, ramghard13],
    },
    // 'Sambhar S1'
    {
      id: 6,
      category: 'Location 6',
      title: 'Location 6',
      images: [
        sambharS1Img1, 
        // sambharS1Img2, sambharS1Img3, sambharS1Img4, sambharS1Img5, sambharS1Img6,
        // sambharS1Img7, sambharS1Img8, sambharS1Img9,
         sambharS1Img10, sambharS1Img11, sambharS1Img12,
        sambharS1Img13, sambharS1Img14
        // , sambharS1Img15, sambharS1Img16, sambharS1Img17, sambharS1Img18,
        // sambharS1Img19, sambharS1Img20, sambharS1Img21, sambharS1Img22, sambharS1Img23, sambharS1Img24,
        // sambharS1Img25, sambharS1Img26, sambharS1Img27, sambharS1Img28, sambharS1Img29, sambharS1Img30,
        // sambharS1Img31, sambharS1Img32, sambharS1Img33, sambharS1Img34, sambharS1Img35, sambharS1Img36,
        // sambharS1Img37, sambharS1Img38, sambharS1Img39, sambharS1Img40, sambharS1Img41, sambharS1Img42,
        // sambharS1Img43, sambharS1Img44, sambharS1Img45, sambharS1Img46, sambharS1Img47, sambharS1Img48,
        // sambharS1Img49, sambharS1Img50, sambharS1Img51, sambharS1Img52, sambharS1Img53, sambharS1Img54,
        // sambharS1Img55, sambharS1Img56, sambharS1Img57, sambharS1Img58, sambharS1Img59, sambharS1Img60,
        // sambharS1Img61, sambharS1Img62, sambharS1Img63, sambharS1Img64, sambharS1Img65, sambharS1Img66,
        // sambharS1Img67, sambharS1Img68, sambharS1Img69, sambharS1Img70, sambharS1Img71, sambharS1Img72,
        // sambharS1Img73, sambharS1Img74, sambharS1Img75, sambharS1Img76, sambharS1Img77, sambharS1Img78,
        // sambharS1Img79, sambharS1Img80, sambharS1Img81, sambharS1Img82, sambharS1Img83, sambharS1Img84,
        // sambharS1Img85, sambharS1Img86, sambharS1Img87, sambharS1Img88, sambharS1Img89, sambharS1Img90,
        // sambharS1Img91, sambharS1Img92, sambharS1Img93
        ],
    },
    
    // 'Sambhar S2'
    {
      id: 7,
      category: 'Location 7',
      title: 'Location 7',
      images: [sambharS2Img1, sambharS2Img10, sambharS2Img11, sambharS2Img12, sambharS2Img13, sambharS2Img14, sambharS2Img15, sambharS2Img16, sambharS2Img17, sambharS2Img18, sambharS2Img19, sambharS2Img20, sambharS2Img21, sambharS2Img22],
    },
    //Shiv Vilash
    {
      id: 8,
      category: 'Shiv Vilash',
      title: 'Location 8',
      images: [shivVilashImg1],
    },

    //ViJay Ran
    {
      id: 9,
      category: 'Location 9',
      title: 'Location 9',
      images: [vijayRanImg1, vijayRanImg10, vijayRanImg11, vijayRanImg12, vijayRanImg13],
    },
    //Kash Bhag
    {
      id: 10,
      category: 'Location 10',
      title: 'Location 10',
      images: [
        kashBhagImg1

      ],
    },
    //Haveli 4 Dar Waja
    {
      id: 11,
      category: 'Location 11',
      title: 'Location 11',
      images: [haveli4DarWajaImg1, haveli4DarWajaImg10, haveli4DarWajaImg11, haveli4DarWajaImg12, haveli4DarWajaImg13, haveli4DarWajaImg14],
    },
    //Alsisar Jaipur
    {
      id: 12,
      category: 'Location 12',
      title: 'Location 12',
      images: [ alsisarJaipurImg10, alsisarJaipurImg11],
    },

    // ALSISER RANTHMBOR
    {
      id: 13,
      category: 'Location 13',
      title: 'Location 13',
      images: [alsisarSawaimadupurImg1, alsisarSawaimadupurImg2, alsisarSawaimadupurImg10, alsisarSawaimadupurImg11, alsisarSawaimadupurImg12, alsisarSawaimadupurImg13, alsisarSawaimadupurImg14, alsisarSawaimadupurImg15, alsisarSawaimadupurImg16, alsisarSawaimadupurImg17, alsisarSawaimadupurImg18, alsisarSawaimadupurImg19, alsisarSawaimadupurImg20, alsisarSawaimadupurImg21, alsisarSawaimadupurImg22, alsisarSawaimadupurImg23, alsisarSawaimadupurImg24],
    },
    //Alsisar Shekawati
    {
      id: 14,
      category: 'Location 14',
      title: 'Location 14',
      images: [ alsisarShekawatiImg10, alsisarShekawatiImg11, alsisarShekawatiImg12, alsisarShekawatiImg13, alsisarShekawatiImg14],
    },
    //RASS Raj Mahel
    {
      id: 15,
      category: 'Location 15',
      title: 'Location 15',
      images: [rassRajMahelImg1, rassRajMahelImg2, rassRajMahelImg10, rassRajMahelImg11, rassRajMahelImg12, rassRajMahelImg13, rassRajMahelImg14, rassRajMahelImg15, rassRajMahelImg16, rassRajMahelImg17, rassRajMahelImg18, rassRajMahelImg19],
    },
    // Amer Fort
    {
      id: 16,
      category: 'Location 16',
      title: 'Location 16',
      images: [amerFortImg1, amerFortImg2, amerFortImg10, amerFortImg11, amerFortImg12, amerFortImg13, amerFortImg14, amerFortImg15, amerFortImg16, amerFortImg17, amerFortImg18, amerFortImg19, amerFortImg20, amerFortImg21, amerFortImg22, amerFortImg23, amerFortImg24],
    },
    // Narghar Fort
    {
      id: 17,
      category: 'Location 17',
      title: 'Location 17',
      images: [nargharFortImg1, nargharFortImg10, nargharFortImg11, nargharFortImg12, nargharFortImg13, nargharFortImg14],
    },
    // Sisodiya Bagh
    {
      id: 18,
      category: 'Location 18',
      title: 'Location 18',
      images: [sisodiyaBaghImg1, sisodiyaBaghImg10, sisodiyaBaghImg11, sisodiyaBaghImg12, sisodiyaBaghImg13, sisodiyaBaghImg14],
    },
    // Blue City
    {
      id: 19,
      category: 'Location 19',
      title: 'Location 19',
      images: [blueCityImg1, blueCityImg2, blueCityImg3, blueCityImg5, blueCityImg6, blueCityImg7, blueCityImg8, blueCityImg9, blueCityImg10, blueCityImg11, blueCityImg12, blueCityImg13, blueCityImg14],
    },
    // Chartiya
    {
      id: 20,
      category: 'Location 20',
      title: 'Location 20',
      images: [chartiyaImg10, chartiyaImg11, chartiyaImg12, chartiyaImg13],
    },
    // Khimwasar Fort
    {
      id: 21,
      category: 'Location 21',
      title: 'Location 21',
      images: [khimwasarFortImg10, khimwasarFortImg11, khimwasarFortImg12, khimwasarFortImg13],
    },
    // Mehran Gahar Fort
    {
      id: 22,
      category: 'Location 22',
      title: 'Location 22',
      images: [mehranGaharFortImg10, mehranGaharFortImg11, mehranGaharFortImg12, 
        mehranGaharFortImg13, mehranGaharFortImg14,
        // , mehranGaharFortImg15, mehranGaharFortImg16, 
        // mehranGaharFortImg17, mehranGaharFortImg18, mehranGaharFortImg19, mehranGaharFortImg20, 
        // mehranGaharFortImg21, mehranGaharFortImg22, mehranGaharFortImg23, mehranGaharFortImg24, 
        // mehranGaharFortImg25, mehranGaharFortImg26, mehranGaharFortImg27, mehranGaharFortImg28, 
        // mehranGaharFortImg29, mehranGaharFortImg30, mehranGaharFortImg31, mehranGaharFortImg32, 
        // mehranGaharFortImg33, mehranGaharFortImg34, mehranGaharFortImg35, mehranGaharFortImg36, 
        // mehranGaharFortImg37, mehranGaharFortImg38, mehranGaharFortImg39, mehranGaharFortImg40, 
        // mehranGaharFortImg41, mehranGaharFortImg42, mehranGaharFortImg43, mehranGaharFortImg44, 
        // mehranGaharFortImg45, mehranGaharFortImg46, mehranGaharFortImg47, mehranGaharFortImg48, 
        // mehranGaharFortImg49, mehranGaharFortImg50, mehranGaharFortImg51, mehranGaharFortImg52, 
        // mehranGaharFortImg53, mehranGaharFortImg54, mehranGaharFortImg55, mehranGaharFortImg56, 
        // mehranGaharFortImg57, mehranGaharFortImg58, mehranGaharFortImg59, mehranGaharFortImg60, 
        // mehranGaharFortImg61, mehranGaharFortImg62, mehranGaharFortImg63, mehranGaharFortImg64, 
        // mehranGaharFortImg65, mehranGaharFortImg66, mehranGaharFortImg67, mehranGaharFortImg68, 
        // mehranGaharFortImg69, mehranGaharFortImg70, mehranGaharFortImg71, mehranGaharFortImg72, 
        // mehranGaharFortImg73, mehranGaharFortImg74, mehranGaharFortImg75, mehranGaharFortImg76, 
        // mehranGaharFortImg77, mehranGaharFortImg78, mehranGaharFortImg79, mehranGaharFortImg80, 
        // mehranGaharFortImg81, mehranGaharFortImg82, mehranGaharFortImg83, mehranGaharFortImg84, 
        // mehranGaharFortImg85, mehranGaharFortImg86, mehranGaharFortImg87, mehranGaharFortImg88, 
        // mehranGaharFortImg89, mehranGaharFortImg90, mehranGaharFortImg91, mehranGaharFortImg92, 
        // mehranGaharFortImg93, mehranGaharFortImg94, mehranGaharFortImg95, mehranGaharFortImg96, 
        // mehranGaharFortImg97, mehranGaharFortImg98, mehranGaharFortImg99, 
        mehranGaharFortImg100, 
        mehranGaharFortImg101, mehranGaharFortImg102, mehranGaharFortImg103, mehranGaharFortImg104, 
        mehranGaharFortImg105, mehranGaharFortImg106, mehranGaharFortImg107, mehranGaharFortImg108, 
        mehranGaharFortImg109, mehranGaharFortImg110, mehranGaharFortImg111, mehranGaharFortImg112, 
        mehranGaharFortImg113, mehranGaharFortImg114, mehranGaharFortImg115, mehranGaharFortImg116, 
        mehranGaharFortImg117, mehranGaharFortImg118, mehranGaharFortImg119, mehranGaharFortImg120, 
        mehranGaharFortImg121, mehranGaharFortImg122, mehranGaharFortImg123, mehranGaharFortImg124, 
        mehranGaharFortImg125, mehranGaharFortImg126, mehranGaharFortImg127, mehranGaharFortImg128, 
        mehranGaharFortImg129, mehranGaharFortImg130, mehranGaharFortImg131, mehranGaharFortImg132, 
        mehranGaharFortImg133, mehranGaharFortImg134, mehranGaharFortImg135, mehranGaharFortImg136, 
        mehranGaharFortImg137, mehranGaharFortImg138
        // , mehranGaharFortImg139, mehranGaharFortImg140, 
        // mehranGaharFortImg141, mehranGaharFortImg142, mehranGaharFortImg143, mehranGaharFortImg144, 
        // mehranGaharFortImg145, mehranGaharFortImg146, mehranGaharFortImg147, mehranGaharFortImg148, 
        // mehranGaharFortImg149, mehranGaharFortImg150, mehranGaharFortImg151, mehranGaharFortImg152, 
        // mehranGaharFortImg153, mehranGaharFortImg154, mehranGaharFortImg155, mehranGaharFortImg156, 
        // mehranGaharFortImg157, mehranGaharFortImg158, mehranGaharFortImg159, mehranGaharFortImg160, 
        // mehranGaharFortImg161, mehranGaharFortImg162, mehranGaharFortImg163, mehranGaharFortImg164, 
        // mehranGaharFortImg165, mehranGaharFortImg166, mehranGaharFortImg167, mehranGaharFortImg168, 
        // mehranGaharFortImg169, mehranGaharFortImg170, mehranGaharFortImg171, mehranGaharFortImg172, 
        // mehranGaharFortImg173, mehranGaharFortImg174, mehranGaharFortImg175, mehranGaharFortImg176, 
        // mehranGaharFortImg177, mehranGaharFortImg178, mehranGaharFortImg179, mehranGaharFortImg180, 
        // mehranGaharFortImg181, mehranGaharFortImg182, mehranGaharFortImg183, mehranGaharFortImg184, 
        // mehranGaharFortImg185, mehranGaharFortImg186, mehranGaharFortImg187, mehranGaharFortImg188, 
        // mehranGaharFortImg189, mehranGaharFortImg190, mehranGaharFortImg191, mehranGaharFortImg192, 
        // mehranGaharFortImg193, mehranGaharFortImg194, mehranGaharFortImg195, mehranGaharFortImg196, 
        // mehranGaharFortImg197, mehranGaharFortImg198, mehranGaharFortImg199, mehranGaharFortImg200, 
        // mehranGaharFortImg201, mehranGaharFortImg202, mehranGaharFortImg203
      ],
    },
    // Jawai
    {
      id: 23,
      category: 'Location 23',
      title: 'Location 23',
      images: [
        jawaiImg1, jawaiImg10, jawaiImg11, jawaiImg12, jawaiImg13, jawaiImg14
      ],      
    },
    // Ghats
    {
      id: 24,
      category: 'Location 24',
      title: 'Location 24',
      images: [
        ghatsImg1, ghatsImg10, ghatsImg11, ghatsImg12, ghatsImg13, ghatsImg14
      ],
    },
    // Street
    
    {
      id: 25,
      category: 'Location 25',
      title: 'Location 25',
      images: [streetImg1],
    },
  ];

  const [activeCategory, setActiveCategory] = useState('All');
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [modalImageIndex, setModalImageIndex] = useState(null);

  const filteredLocations =
    activeCategory === 'All'
      ? locations
      : locations.filter((location) => location.category === activeCategory);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setSelectedLocation(null); // Reset the selected location when changing category
    setModalImageIndex(null); // Reset the modal image index
  };

  const handleViewLocation = (location) => {
    setSelectedLocation(location);
    setModalImageIndex(0); // Set the first image to display in the modal
  };

  const handleImageClick = (index) => {
    setModalImageIndex(index); // Set the clicked image index to preview in modal
  };

  const handleCloseModal = () => {
    setModalImageIndex(null); // Close the modal
  };

  const handleNextImage = () => {
    if (modalImageIndex < selectedLocation.images.length - 1) {
      setModalImageIndex(modalImageIndex + 1); // Go to the next image
    }
  };

  const handlePreviousImage = () => {
    if (modalImageIndex > 0) {
      setModalImageIndex(modalImageIndex - 1); // Go to the previous image
    }
  };

  // Close the modal when clicked outside the modal content
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('modal-overlay')) {
      handleCloseModal(); // Close if the overlay itself is clicked
    }
  };

  return (
    <section className="locations-section">
      <div className="container">
        <h2 className="locations-title">Location Categories</h2>
        <div className="locations-filter">
          {categories.map((category) => (
            <button
              key={category}
              className={`filter-button ${activeCategory === category ? 'active' : ''}`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* If a location is selected, show full images */}
        {selectedLocation ? (
          <div className="locations-full-view">
            <h2 className="location-title">{selectedLocation.title}</h2>
            <div className="locations-full-grid">
              {selectedLocation.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${selectedLocation.category} ${index + 1}`}
                  className="location-image"
                  onClick={() => handleImageClick(index)} // Click image to open modal
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="locations-grid">
            {filteredLocations.map((location) => (
              <div className="location-card" key={location.id}>
                <img
                  src={location.images[0]}
                  alt={`${location.category} ${location.title}`}
                  className="location-image"
                  onClick={() => handleViewLocation(location)} // View location images
                />
                <h3 className="location-title">{location.title}</h3>
                <button className="view-button" onClick={() => handleViewLocation(location)}>
                  View
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Modal for Image Preview */}
        {modalImageIndex !== null && selectedLocation && (
          <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
              <button className="modal-close" onClick={handleCloseModal}>
                &times;
              </button>
              <div className="modal-image-container">
                <button className="modal-prev" onClick={handlePreviousImage}>&lt;</button>
                <img
                  src={selectedLocation.images[modalImageIndex]}
                  alt={`Preview ${modalImageIndex + 1}`}
                  className="modal-image"
                />
                <button className="modal-next" onClick={handleNextImage}>&gt;</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Locations;
