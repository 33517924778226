import React from 'react';
import CustomParticleAnimation from '../components/CustomParticleAnimation';
import TitleSubtitle from '../components/TitleSubtitle'; 
import Locations from '../components/Location'; 
import './LocationPage.css'; 
import OurClients from '../components/OurClients'
import ProjectCategories from '../components/ProjectCategories'
import Testimonials from '../components/Testimonials'
import HireUsNow from '../components/HireUsNow';
import Footer from '../components/Footer'

const LocationPage = () => {
  return (
    <section className="location-page">
      <div className="hero-section">
        <CustomParticleAnimation />
        <TitleSubtitle /> 
      </div>
      <Locations /> 
      <OurClients />
      <ProjectCategories />
      <Testimonials />
      <HireUsNow />
      
      <Footer />
    </section>
  );
};

export default LocationPage;
