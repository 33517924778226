import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RecentProjects.css';

const RecentProjects = () => {
  const navigate = useNavigate();

  const projects = [
    {
      id: 1,
      title: 'Twamev - Truly You',
      image: 'https://img.youtube.com/vi/OFiHoLAAyPw/hqdefault.jpg',
      description: 'Truly You.',
      link: 'https://youtu.be/OFiHoLAAyPw', // Direct YouTube link
    },
    {
      id: 2,
      title: 'Twamev - A mix of past',
      image: 'https://img.youtube.com/vi/5TpJN-lGyPU/hqdefault.jpg',
      description: 'A mix of past.',
      link: 'https://youtu.be/5TpJN-lGyPU', // Direct YouTube link
    },
    {
      id: 3,
      title: 'Twamev - I am grace',
      image: 'https://img.youtube.com/vi/KjGsKOiznPM/hqdefault.jpg',
      description: 'I am grace.',
      link: 'https://youtu.be/KjGsKOiznPM', // Direct YouTube link
    },
    {
      id: 4,
      title: 'Mismatched S3 | Netflix India',
      image: 'https://img.youtube.com/vi/b87uNYutCLs/hqdefault.jpg',
      description: 'Dimple and Rishi are back again, to spread some love with your Favourite Comfort Show! Mismatched S3.',
      link: 'https://www.youtube.com/watch?v=b87uNYutCLs&ab_channel=NetflixIndia',
    },
    {
      id: 5,
      title: 'Mr. & Mrs. Mahi | Netflix India',
      image: 'https://img.youtube.com/vi/vB7OiVfmEDM/hqdefault.jpg',
      description: 'Watch Mr. & Mrs. Mahi live their dreams and not their parents’, in this heartfelt, inspiring drama.',
      link: 'https://www.youtube.com/watch?v=vB7OiVfmEDM&ab_channel=NetflixIndia',
    },
    {
      id: 6,
      title: 'Air India New Identity',
      image: 'https://img.youtube.com/vi/al51WZcN2_U/hqdefault.jpg',
      description: 'Reimagining the legacy of Air India with a fresh identity and vision.',
      link: 'https://www.youtube.com/watch?v=al51WZcN2_U&ab_channel=AirIndiaOfficial',
    },
    {
      id: 7,
      title: 'Tata Motors Cars | Built for the Bold',
      image: 'https://img.youtube.com/vi/9QFe0eELKws/hqdefault.jpg',
      description: 'Explore the bold and innovative designs of Tata Motors cars.',
      link: 'https://www.youtube.com/watch?v=9QFe0eELKws&ab_channel=TataMotorsCars',
    },
    {
      id: 8,
      title: 'Parachute Advansed | Coconut Hair Oil',
      image: 'https://img.youtube.com/vi/rJj-HQ3mJvE/hqdefault.jpg',
      description: 'Rediscover the timeless beauty of coconut hair oil with Parachute Advansed.',
      link: 'https://www.youtube.com/watch?v=rJj-HQ3mJvE&ab_channel=ParachuteAdvansed',
    },
    {
      id: 9,
      title: 'Rajasthan Tourism | Discover Rajasthan',
      image: 'https://img.youtube.com/vi/nZP9OL698as/hqdefault.jpg',
      description: 'Discover the vibrant culture and heritage of Rajasthan.',
      link: 'https://www.youtube.com/watch?v=nZP9OL698as&ab_channel=RajasthanTourism',
    },
  ];

  return (
    <section className="recent-projects">
      <div className="container">
        <h2 className="section-title">Recent Projects</h2>
        <div className="projects-grid">
          {projects.map((project) => (
            <div
              className="project-card"
              key={project.id}
              onClick={() => window.open(project.link, '_blank')} // Opens YouTube link on click
            >
              <img src={project.image} alt={project.title} className="project-image" />
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-description">{project.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="cta-container">
          <button className="view-all-button" onClick={() => navigate('/contact')}>
            Contact
          </button>
        </div>
      </div>
    </section>
  );
};

export default RecentProjects;
